const initCalendar = () => {
  var calendarEL = document.getElementById('calendar');
  var calendar = new Calendar(calendarEL, {
    locales: allLocales,
    locale: 'fr', // the initial locale
    plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
    initialView: 'dayGridWeek',
    contentHeight: 'auto',
    events: JSON.parse(document.querySelector('[data-resources]').dataset.resources),
    editable: true,
    droppable: true,
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,listWeek'
    },
    eventOrder: function(info) {
      [info.ui.borderColor]
    },
    eventClick: function(info) {
      // add class to find the element if we want to remove it
      info.el.classList.add('can-be-removed');

      if (info.event._def.publicId == '') {
        const newId = info.el.dataset.newId;
        info.event._def.publicId = newId;
      }

      const fullId = info.event.id.split('-');
      const id = fullId[1]
      const class_name = fullId[0]
      $.ajax({
        url: `trainings/${id}`,
        type: 'GET',
        data: { class: class_name },
        dataType: 'script'
      });
    },
    eventDidMount: function(info) {
      info.el.classList.add("pointer");
    },
    eventDrop: function (info) {
      if (info.event._def.publicId == '') {
        const newId = info.el.dataset.newId;
        info.event._def.publicId = newId;
      }
      const fullId = info.event.id.split('-');
      const id = fullId[1]
      const class_name = fullId[0]
      const newDate = info.event.start

      if (class_name == 'goal') {
        info.revert()
      } else if (class_name == 'training') {
        $.ajax({
          url: `trainings/${id}`,
          type: 'PATCH',
          data: { new_date: newDate, class: class_name },
          dataType: 'script'
        });
      }
    },
    drop: function (info) {
      const date = info.dateStr;
      const trainingResourceId = info.draggedEl.dataset.trainingResourceId;
      const planningId = info.draggedEl.dataset.planningId;
      if (info.draggedEl.parentElement.children.length == 1) {
        info.draggedEl.parentElement.parentElement.remove();
      }
      info.draggedEl.parentNode.removeChild(info.draggedEl);

      $.ajax({
        url: 'trainings',
        type: 'POST',
        data: {
                date: date,
                training_resource_id: trainingResourceId,
                training: { planning_id: planningId }
              },
        dataType: 'script',
      });
    }
  });

  calendar.render();

  const additionalList = document.getElementById('additional-trainings');
  if (additionalList) {
    new Draggable(additionalList, {
      itemSelector: '.additional-training',
      eventData: function(eventEl) {
        return {
          title: eventEl.dataset.title,
          color: eventEl.dataset.color,
          textColor: 'white',
          classNames: ["padding-vertical-10px padding-lateral-10px word-break to-update"]
        }
      }
    });
  }
};

export { initCalendar };

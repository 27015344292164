import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  static values = {
    url: String
  }

  back() {
    document.getElementById('chose-template').classList.remove('d-none');
    document.getElementById('schedule-create').classList.add('d-none');
    const goal = document.getElementById('goal_selection_goal_id')
    const goalId = goal.value
    goal.value = '0';
    goal.value = goalId;
  }

  updatePlanningDetails() {
    localStorage.setItem('planning_goal_id', document.getElementById('goal_selection_goal_id').value)
    localStorage.setItem('planning_template_id', document.getElementById('planning_template').value )
    localStorage.setItem('planning_include_general', document.getElementById('planning_include_general_true').checked )
  }

  generate() {
    displaySpinner();
    fetch(`/plannings`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        planning: {
          template_sessions: JSON.parse(localStorage.getItem('planning_trainings')),
          goal_id: localStorage.getItem('planning_goal_id'),
          template_id: localStorage.getItem('planning_template_id'),
          include_general: localStorage.getItem('planning_include_general'),
        }
      })
    }).then(response => response.json())
      .then(data => {
        window.open(data.path, '_self');
      })
  }
}

const displaySpinner = () => {
  const container = document.getElementById('schedule-create');
  container.innerHTML = `
    <div class="margin-bottom-30px">Veuillez patienter, nous préparons votre programme avec soin...</div>
    <div class="spinner-grow text-secondary" style="width: 100px; height: 100px;" role="status"></div>
  `
};

const displayGoalDetails = () => {
  const goalArrows = [...document.querySelectorAll('.show-goal-info')];

  goalArrows.forEach(goal => {
    goal.addEventListener('click', event => {
      const chevron = event.currentTarget;
      const id = chevron.dataset.goalId;
      const infos = document.getElementById(`goal-info-${id}`);
      infos.classList.toggle('d-none');

      if (infos.classList.contains('d-none')) {
        document.getElementById(`show-goal-info-${id}`).classList.remove('fa-chevron-down');
        document.getElementById(`show-goal-info-${id}`).classList.add('fa-chevron-right');
      } else {
        document.getElementById(`show-goal-info-${id}`).classList.remove('fa-chevron-right');
        document.getElementById(`show-goal-info-${id}`).classList.add('fa-chevron-down');
      }
    });
  });
};

function showRaceFormat(value) {
  $.ajax({
    url: '/goals/new',
    type: "get",
    dataType: 'script',
    data: { race_type_value: value }
  });
}

function showRaceDistances(value) {
  var race_type = document.getElementById('goal_race_type').value
  $.ajax({
    url: '/goals/new',
    type: "get",
    dataType: 'script',
    data: { race_format: value, race_type: race_type }
  });
}

export { displayGoalDetails, showRaceFormat, showRaceDistances };

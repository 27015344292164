function switchBikeReferences() {
  let outsideDivTests = document.getElementById('outside-powers-tests');
  let insideDivTests = document.getElementById('inside-powers-tests');
  outsideDivTests.classList.toggle('d-none');
  insideDivTests.classList.toggle('d-none');
  document.getElementById('outside-references').classList.toggle('underline');
  document.getElementById('inside-references').classList.toggle('underline');
}

function switchBikeZones() {
  let outsideDivZones = document.getElementById('outside-powers-zones');
  let insideDivZones = document.getElementById('inside-powers-zones');
  outsideDivZones.classList.toggle('d-none');
  insideDivZones.classList.toggle('d-none');
  document.getElementById('outside-power-zones').classList.toggle('underline');
  document.getElementById('inside-power-zones').classList.toggle('underline');
}

function updateFtp(np, ftp) {
  np.addEventListener('keyup', e => {
    ftp.value = Math.round(+np.value * 0.951243);
  });
}

function updateNp(np, ftp) {
  ftp.addEventListener('keyup', e => {
    np.value = Math.round(+ftp.value * 1.0526);
  });
}

export { switchBikeReferences, switchBikeZones, updateFtp, updateNp };

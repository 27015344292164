import places from 'places.js';

const initPlaces = () => {
  const userAddress = document.getElementById('user_information_address');
  if (userAddress) {
    places({ container: userAddress });
  }
};

export { initPlaces };

var filter = {type: ''};
function filterRunTests(element) {
  let runTestType = element.dataset.runTestType

  const selected = element.classList.contains('toggle-run-test-type');
  if (selected) {
    filter.type = '';
    element.classList.remove('toggle-run-test-type');
  } else {
    filter.type = runTestType;
    document.querySelectorAll('.toggle-run-test-type').forEach(element => element.classList.remove('toggle-run-test-type'));
    element.classList.add('toggle-run-test-type');
  }
  
  $.ajax({
    url: '/run_tests',
    type: "GET",
    dataType: 'script',
    data: { filter: filter }
  });
}

export { filterRunTests }; 

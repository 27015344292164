import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['minutes', 'seconds', 'pace', 'vma', 'seuil30', 'seuil60', 'semiMarathon', 'sweetSpot', 'marathon', 'aérobie', 'ef']

  calcul() {
    const distance = 10 * 3600;
    const sec = (+this.minutesTarget.value * 60) + +this.secondsTarget.value;
    const pace = distance / sec;
    const vma = pace / 0.89;
    this.paceTarget.innerText = `${pace.toFixed(2)} km/h - ${minkm(pace)}min/km`;
    this.vmaTarget.innerText = `${vma.toFixed(2)} km/h - ${minkm(vma)}min/km`;

    this.seuil30Target.innerText = `${(vma * 0.8982).toFixed(2)} km/h - ${minkm(vma * 0.8982)}min/km`;
    this.seuil60Target.innerText = `${(vma * 0.865).toFixed(2)} km/h - ${minkm(vma * 0.865)}min/km`;
    this.semiMarathonTarget.innerText = `${(vma * 0.8535).toFixed(2)} km/h - ${minkm(vma * 0.8535)}min/km`;
    this.sweetSpotTarget.innerText = `${(vma * 0.8193).toFixed(2)} km/h - ${minkm(vma * 0.8193)}min/km`;
    this.marathonTarget.innerText = `${(vma * 0.766).toFixed(2)} km/h - ${minkm(vma * 0.766)}min/km`;
    this.aérobieTarget.innerText = `${(vma * 0.7).toFixed(2)} km/h - ${minkm(vma * 0.7)}min/km`;
    this.efTarget.innerText = `${(vma * 0.66).toFixed(2)} à ${(vma * 0.6).toFixed(2)} km/h - ${minkm(vma * 0.66)}min/km à ${minkm(vma * 0.6)}min/km`;
  };
};

const minkm = speed => {
  return `${Math.trunc(60 / speed)}:${((60 / speed%1)*60/100).toFixed(2).replace('0.', '')}`;
}

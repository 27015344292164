import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nameInput", "descriptionInput"]

  onBlur() {
    $.ajax({
      url: "/admin/training_weeks/" + this.nameInputTarget.id.split('-')[3],
      type: 'PATCH',
      data: { 
        training_week: { 
          name: this.nameInputTarget.value,
          description: this.descriptionInputTarget.value
        }
      },
      dataType: 'script'
    });
  }

  toggleWeeks(e) {
    document.querySelector(`#${e.target.dataset.weekType}-button i`).classList.toggle('bottom-arrow');
    document.querySelector(`#${e.target.dataset.weekType}-button i`).classList.toggle('right-arrow');
    document.getElementById(`${e.target.dataset.weekType}-list`).classList.toggle('displayWeeks');
    document.getElementById(`${e.target.dataset.weekType}-list`).classList.toggle('hideWeeks');
    setTimeout(() => {
      document.getElementById(`${e.target.dataset.weekType}-list`).classList.toggle('d-none');
    }, 300);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  static values = {
    url: String
  }

  open(event) {
    const url = event.currentTarget.dataset.goalsUrlValue;
    fetch(url)
    .then(data => data.json())
    .then(data => fill_content(data.content))
  }
}

const fill_content = content => {
  document.getElementById('goal-debrief-content').innerHTML = content;
  $('#goal-debrief').modal('show');
};
